import React from "react";
import Chart from "react-apexcharts";
import { Container, Row } from "reactstrap";
import { install } from "resize-observer";

import { TfiLocationArrow } from 'react-icons/tfi';
import { GiDivergence, GiThornedArrow } from 'react-icons/gi';
import { GiBroadheadArrow } from 'react-icons/gi';

install();

function MeterToKnots(meters){
    const meterToKnots=1.9438445
    return (meters * meterToKnots).toPrecision(5)
  }
  
function NavPointer(degrees, index){
  const newDegrees  = degrees + 45 + "deg"
  // console.log(index)
  // const tfiUpdate = (<TfiLocationArrow size='10px' style = {{transform: rotate(${degrees} + 'deg')}} />)
  return  <GiBroadheadArrow size="18px" style = {{transform: `rotate(${newDegrees})`}} /> 
}

function renderWindIcon(props) {
  //console.log(props)
  return(
      props.data.map(
      (
        { windDirection } , index
      ) => (
            <span key={index}>
              { NavPointer(windDirection.noaa, `${index}-${props.location}`) }
            </span>
            )
      )
  )
}

const WindChart = (props) => {
    //console.log(props)

    const state = {
        location: props.location,
        options: {
          colors: ['lightseagreen', '#1561adb6', '#76c1d4b4', '#E91E63'],
          grid: {
            borderColor: '#000',
            strokeDashArray: 1,
            position: 'front',
          },
          chart: {
            id: "basic-bar" + props.location,
            // stacked: false,
            // dropShadow: {
            //   enabled: true,
            //   color: '#000',
            //   top: 18,
            //   left: 7,
            //   blur: 6,
            //   opacity: 0.2
            // },
            toolbar: { 
              show: true
            },
          },
          xaxis: {
            categories: props.data.map(({ time }) => (`${time.split('+')[0].split(':')[0]}:${time.split('+')[0].split(':')[1]}`)),
            type: 'category'
          },

          yaxis: [
            {
              show: true,
              seriesName: 'Temperature in Celcius',
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: 'lightseagreen'
              },
              labels: {
                show: true,
                style: {
                  colors: 'lightseagreen',
                }
              },
              title: {
                text: "Temperature in Celcius",
                style: {
                  color: 'lightseagreen',
                }
              },
            },
            {
              opposite: false,
              seriesName: 'WindSpeed in Knots',
              min: 0,
              max: 55,
              show: true,
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#1561ad'
              },
              labels: {
                style: {
                  colors: '#1561ad',
                }
              },
              title: {
                text: "WindSpeed in Knots",
                style: {
                  color: '#1561ad',
                }
              },
            },
      
           
            {
              opposite: true,
              seriesName: 'WindSpeed gusts in Knots',
              min: 0,
              max: 55,
              show: true,
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: 'grey'
              },
              labels: {
                style: {
                  colors: 'grey',
                }
              },
              title: {
                text: "WindSpeed gusts in Knots",
                style: {
                  color: 'black',
                }
              },
            },
            {
              opposite: true,
              seriesName: 'WindDirection',
              min: 0,
              max: 360,
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: 'black'
              },
              labels: {
                style: {
                  colors: 'black',
                }
              },
              title: {
                text: "Wind direction in degrees. 0=North",
                style: {
                  color: 'black',
                }
              },
            },
  
              
          ],
          stroke: {
            width: [2, 3, 3, 2]
          },
        },
        series: [
          {
            name: "Temperature in Celcius",
            type: 'line',
            // color: 'lightseagreen',
            data: props.data.map(({ airTemperature }) => airTemperature.noaa)
          },
          {
            name: "WindSpeed in Knots",
            type: 'area',
            // color: '#1561adb6',
            //color: '#76c1d456',
            data: props.data.map(({ windSpeed }) => MeterToKnots(windSpeed.noaa))
          },
          {
            name: "WindSpeed gusts in Knots",
            type: 'area',
            //color: '#1561adb6',
            // color: '#76c1d4b4',
            data: props.data.map(({ gust }) => MeterToKnots(gust.noaa))
          },
          {
            name: "WindDirection",
            type: 'line',
            // colors: 'red',
            data: props.data.map(({ windDirection }) => windDirection.noaa)
          }
        ],
      };


    return(
    <div>
        {props.data ? (
          <>
            <Row>
              <Chart
                  options={state.options}
                  series={state.series}
                  type="line"
                  height="800px"
              />
            </Row>
          Wind blowing in this direction over the course of the timeline above.<br />
          {renderWindIcon(props)}
          </>
        
        ) : "Nothing here"}
    </div>
    );
};

export default WindChart;